"use client";
import React, { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import { metadata } from "./metadata.ts";
import Navbar from "./Home/navbar.tsx";
import Navbar2 from "./Home/MobileNavbar.tsx";
import CardEffect from "./Home/cardseffect.tsx";
import ContactForm from "./Home/contact.tsx";
import Footer from "./Home/footer.tsx";
import Typewriter from "typewriter-effect";
import Script from "next/script";

export default function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const [showFullText, setShowFullText] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleReadMore = () => {
    setShowFullText(true);
  };

  const handleViewLess = () => {
    setShowFullText(false);
  };

  return (
    <main className="gradient-bg">
      <head>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
      </head>
      <Script
        id="ga-script"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-21492NPCH3"
      ></Script>
      <Script id="ga-script">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-21492NPCH3');
        `}
      </Script>
      <div className="nav1">{isMobile ? <Navbar2 /> : <Navbar />}</div>
      <div className="flex flex-row">
        <div className="flex flex-col items-center justify-center h-screen bg-transparent text-white w-screen">
          <div className="flex flex-col items-center justify-center w-full h-1/2 px-12 sm:mt-28 xl:mr-96">
            <h1 className="text-3xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-7xl font-bold leading-tight sm:m-16 md:m-32 lg:m-24 xl:m-8 xl:mr-48">
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(
                      '<span style="color: #b31c96; background-image: -webkit-linear-gradient(0deg, #b31c96 26%, #1e2dbd 100%, #6f54a4 47%); background-clip: text; -webkit-background-clip: text; text-align: center; text-fill-color: transparent; -webkit-text-fill-color: transparent">COME.LEARN.EARN</span>'
                    )
                    .pauseFor(2500)
                    .deleteAll()
                    .pauseFor(1500)
                    .start();
                }}
              />
            </h1>
            <h1 className="text-xl w-9/10 md:w-1/2 lg:w-1/2 xl:w-1/2 font-comfortaa">
              An initiative by SwapSo to deliver Web3 education.
            </h1>
            <div className="bg-cyan-950 text-cyan-400 border border-cyan-400 border-b-4 relative px-8 py-2 rounded-md hover:brightness-150 hover:border-t-4 hover:border-b active:opacity-75 outline-none duration-300 group mt-8 font-bold xl:mr-64">
              <a href="/UniversityProposal.pdf" download="UniversityProposal.pdf" target="_blank" rel="noopener noreferrer">
                Download Deck
              </a>
            </div>
          </div>
        </div>
        <video
          autoPlay
          muted
          loop
          playsInline
          controls={false}
          height="100vh"
          className="absolute z-[-1] w-full lg:w-1/2 lg:mr-64 mx-auto xl:w-1/2 object-cover md:h-3/4 lg:h-3/4 xl:h-3/4 lg:mt-32 xl:mt-32 right-0"
        >
          <source src="/cubic.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        className="relative border border-cyan-400 w-4/5 mx-auto rounded-lg mb-16"
        id="about"
      >
        <h2 className="mt-16 text-5xl mb-4 text-left text-transparent text-white ml-8 lg:ml-32 lg:ml-32 font-comfortaa">
          About <span className="text-cyan-300">Swapso</span>
        </h2>
        <div className="mt-12 mx-auto rounded-lg text-center flex flex-col xl:flex-row lg:flex-row">
          {isMobile ? (
            <p></p>
          ) : (
            <div className="w-screen lg:mt-16 lg:ml-32 xl:ml-32 md:w-1/2">
              <Image
                src="/about2.png"
                alt="logo"
                height={150}
                width={200}
                className="shadow-lg"
              />
            </div>
          )}
          <div className="lg:mt-16 mx-auto ml-8 mr-8 lg:ml-24 xl:ml-24">
              <div className="text-justify">
                <h1 className="w-full text-xl text-white mx-auto text-justify font-comfortaa mb-8 lg:mb-24 xl:mb-24">
                  SwapSo Academy is an initiative by SwapSo aimed at creating a Bitcoin financing platform that makes Bitcoin easy to access for everyone. Our goal is to connect blockchain education with Indian universities, helping students and professionals learn about this growing technology. SwapSo Academy is dedicated to making India ready for the future by offering resources and courses that teach the basics of blockchain, ensuring the country can lead in the world of Web3 and cryptocurrency.              
                </h1>
              </div>
            </div>
          </div>
      </div>
      <h2 className="text-5xl mt-24 mb-4 text-center text-transparent bg-clip-text bg-white font-semibold font-comfortaa p-5"  id="highlights">
        {" "}
        Highlights
      </h2>
    <section className="py-16 bg-black text-white items-center">
      <div className="w-4/5 mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 lg:justify-between gap-8">
          <div className="backdrop-filter backdrop-blur-lg bg-black bg-opacity-5 border border-cyan-400 rounded-xl p-6 text-center flex-1">
              <Image
                src="/soonami.png"
                alt="logo"
                height={150}
                width={330}
                className=""
              />
            <h3 className="text-xl font-bold mb-2 text-cyan-200 mt-2">VC Soonami.io Venturethon Winner</h3>
            <p className="text-gray-300">Emerged victorious in the Soonami.io Venturethon</p>
          </div>
          <div className="bg-black border border-cyan-400 rounded-xl p-6 text-center flex-1">
            <Image
              src="/stanford.png"
              alt="logo"
              height={150}
              width={330}
              className=""
            />
            <h3 className="text-xl font-bold mb-2 text-cyan-200 mt-4">Stanford Seed Spark Program</h3>
            <p className="text-gray-300">Selected for the prestigious Stanford Seed Spark program</p>
          </div>
          <div className="bg-black border border-cyan-400 rounded-xl p-6 text-center flex-1">
            <Image
              src="/startupIndia.png"
              alt="logo"
              height={150}
              width={330}
              className=""
            />
            <h3 className="text-xl font-bold mb-2 text-cyan-200">Seed Funded</h3>
            <p className="text-gray-300">Funded by Startup India</p>
          </div>
          <div className="bg-black border border-cyan-400 rounded-xl p-6 text-center flex-1 flex flex-col items-center justify-center">
            <Image
              src="/iitb.svg"
              alt="logo"
              height={150}
              width={150}
              className="mb-4"
            />
            <h3 className="text-xl font-bold mb-2 text-cyan-200">IIT Bombay based</h3>
            <p className="text-gray-300">Founded by IIT Bombay Scholars</p>
          </div>

          <div className="bg-black border border-cyan-400 rounded-xl p-6 text-center flex-1 flex flex-col items-center justify-center">
            <Image
              src="/microsoft.svg"
              alt="logo"
              height={150}
              width={170}
              className="mb-4"
            />
            <h3 className="text-xl font-bold mb-2 text-cyan-200">Microsoft</h3>
            <p className="text-gray-300">Previously part of Microsoft for Startup FoundersHub</p>
          </div>
          <div className="bg-black border border-cyan-400 rounded-xl p-6 text-center flex-1 flex flex-col items-center justify-center">
            <Image
              src="/wiselogo.png"
              alt="logo"
              height={150}
              width={170}
              className="mb-4"
            />
            <h3 className="text-xl font-bold mb-2 text-cyan-200">WISE SNDT</h3>
            <p className="text-gray-300">Incubated at WISE</p>
          </div>
        </div>
      </div>
    </section>
    <h2 className="text-5xl mb-4 text-center text-transparent bg-gradient-to-r bg-clip-text bg-white font-semibold font-comfortaa p-5 mt-8 mb-8">
       What we offer
    </h2>
    <CardEffect />

      <div className="mt-24" id="partners">
        <h2 className="text-5xl mb-4 text-center text-transparent bg-clip-text bg-white font-semibold font-comfortaa p-5 mt-8 mb-8">
          Partner Universities
        </h2>
      </div>
      <section className="py-16 text-white">
      <div className="w-4/5 mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 lg:justify-between gap-8">
          <div className="rounded-xl p-6 text-center flex-1 bg-white  flex flex-col items-center justify-center">
              <Image
                src="/glbajaj_logo.png"
                alt="logo"
                height={150}
                width={250}
                className=""
              />
            <h3 className="text-3xl font-poppins font-bold mb-2 text-black">GL Bajaj</h3>
          </div>
          <div className="bg-white rounded-xl p-6 text-center flex-1  flex flex-col items-center justify-center">
            <Image
              src="/bhilai.png"
              alt="logo"
              height={150}
              width={250}
              className=""
            />
            <h3 className="mt-14 text-3xl font-poppins font-bold mb-2 text-black">University of Bhilai</h3>
          </div>
          <div className="bg-white rounded-xl p-6 text-center flex-1 flex flex-col items-center justify-center">
            <Image
              src="/pune_logo.png"
              alt="logo"
              height={150}
              width={250}
              className=""
            />
            <h3 className="text-3xl font-poppins font-bold mb-2 text-black">University of Pune</h3>
          </div>
        </div>
      </div>
    </section>
      <div>
        <ContactForm />
      </div>
      <Footer />
    </main>
  );
}
