import React from "react";
import { FaClipboardList, FaGraduationCap, FaUsers } from 'react-icons/fa';
import './cardeffect.css';
import Image from "next/image";

const CardEffect = () => {

    return (
        <div className="container1 mx-auto mt-16 text-center">
     <div className="card">
     <div className="face face1">
      <div className="content flex flex-col items-center justify-center h-full">
        <i className="fab fa-windows"></i>
        <FaClipboardList className="text-4xl text-white mb-4" />
        <p className="font-bold text-3xl">Blockchain/Web3 Electives</p>
      </div>
    </div>
       <div className="face face2">
         <div className="content">
           <p className="text-lg text-gray-200"> SwapSo can provide all the help and resources required to start running Blockchain-based elective courses, allowing students to learn about decentralized technologies and their applications.</p>
         </div>
       </div>
    </div>
    
    <div className="card">
       <div className="face face1">
         <div className="content flex flex-col items-center justify-center h-full">
            <i className="fab fa-android"></i>
            <FaGraduationCap className="text-4xl text-white mb-4" />
            <p className="font-bold text-3xl">Blockchain based degree programs</p>
          </div>
       </div>
       <div className="face face2">
         <div className="content">
           <p className="text-lg text-gray-200">SwapSo can provide all the educational resources and manpower required to start diploma or fulltime program based on Blockchain under the university.</p>
         </div>
       </div>
    </div>
    
    
    <div className="card">
       <div className="face face1">
         <div className="content flex flex-col items-center justify-center h-full">
           <i className="fab fa-apple"></i>
           <FaUsers className="text-4xl text-white mb-4" />
           <p className="font-bold text-3xl">SwapSo College Chapters</p>
           </div>
       </div>
       <div className="face face2">
         <div className="content">
           <p className="text-lg text-gray-200">SwapSo can establish and remotely manage student college chapters/Clubs to spread web3 awareness by organising events, seminars and hackathons.</p>
         </div>
       </div>
    </div>
    
  </div>
    );
};
export default CardEffect;