import React, { useState, useEffect, useRef, useTransition } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from 'next/navigation';
import { Puff } from 'react-loader-spinner';
import "./MobileNavbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link as ScrollLink } from 'react-scroll';

library.add(faBars, faTimes);

function MobileNavbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isPending, startTransition] = useTransition();
  const router = useRouter();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const navbarRef = useRef(null);
  // let lastScrollTop = 0;
  const delta = 5;

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window === "undefined") return;

      const currentScroll = window.scrollY || 0;
      const navbar: any = navbarRef.current;

      if (!navbar) return;

      if (Math.abs(lastScrollTop - currentScroll) <= delta) return;

      if (
        currentScroll > lastScrollTop &&
        currentScroll > navbar.offsetHeight
      ) {
        navbar.style.transform = "translateY(-100%)";
      } else {
        navbar.style.transform = "translateY(0)";
      }

      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };

    window.addEventListener("scroll", handleScroll);

    const navbar: any = navbarRef.current;
    if (navbar) {
      navbar.style.transition = "transform 0.3s ease";
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);


  const handleRouteChange = (url : any) => {
    startTransition(() => {
      router.push(url);
    });
  };

  return (
    <>
    {isPending && (
      <div className="loader-container1">
        <Puff color="#00BFFF" height={100} width={100} />
      </div>
    )}
    <nav className="mobile__navbar">
      <div className="mobile__inner__navbar">
        <div className="left_navbar">
        <Link href="/" className="flex items-center space-x-2 text-2xl text-cyan-400">
  <Image src='/newlogo.svg' width={40} height={40} alt="academy logo" />
  <span className="mt-4">Academy</span>
</Link>
        </div>
        <div className="right_navbar">
          <button className="navbar__icon" onClick={handleMenuToggle}>
            <FontAwesomeIcon icon={"bars"} size="2x" />
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="mobile__navbar__menu">
          <button className="close__icon" onClick={handleMenuClose}>
            <FontAwesomeIcon icon={"times"} size="2x" />
          </button>
          <ul className="inner__mobile__navbar__menu no-bullet mt-4">
            <li className="p-1">
              <ScrollLink to="about" onClick={handleMenuClose} href="/">
                About
              </ScrollLink>
              <hr />
            </li>
            <li className="p-1">
              <ScrollLink to="highlights" onClick={handleMenuClose} href="/">
                Highlights
              </ScrollLink>
              <hr />
            </li>
            <li className="p-1">
              <ScrollLink to="partners" onClick={handleMenuClose} href="/">
                Partners
              </ScrollLink>
              <hr />
            </li>
            {
              <div>
                {/* {!user ? (
                  <button className="ml-4 font-bold bg-purple-500 w-40 p-1 rounded-lg font-bold mt-4">
                    <a href="/signup">Sign in</a>
                  </button>
                ) : (
                  <button
                    className="ml-4 bg-purple-500 w-40 p-1 rounded-lg font-bold mt-4"
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </button>
                )} */}
              </div>
            }
          </ul>
        </div>
      )}
    </nav>
    </>
  );
}

export default MobileNavbar;
